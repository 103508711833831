import React, { FC, useEffect, useState } from "react";
import { ApiUrl } from "../../../../../types/ApiUrl";
import { useHttp } from "../../../../../hooks/useHttp";
import { Button, Pagination, Select, Table } from "antd";
import NotificationAlert from "../../../../../components/Notification";
import { ROLES } from "../../../../../types/constants";
import { useTypedSelector } from "../../../../../hooks/useTypedSelector";
import TextArea from "antd/es/input/TextArea";

interface IDefects {
    sku: string;
}

export const Defects: FC<IDefects> = ({ sku }) => {
    const { user } = useTypedSelector((state) => state.auth);

    const [productDefects, setProductDefects] = useState<any>(undefined);
    const [productsDefectsCount, setProductsDefectsCount] = useState(0);
    const [limit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const { request } = useHttp();


    const fetchData = async () => {
        const response = await request({
            url: `${ApiUrl.PRODUCT}/defects?sku=${sku}&limit=${limit}&offset=${offset}`,
            method: 'get',
        });
        setProductDefects(response.data?.items || []);
        setProductsDefectsCount(response.data?.count || 0);
    }
    useEffect(() => {
        fetchData();
    }, [sku, currentPage]);
    const handlePagination = (currentPage: number, limit: number) => {
        setOffset(currentPage);
        setCurrentPage(currentPage);
    }
    const onShowSizeChange = (currentPage: number, limit: number) => {
        setOffset(currentPage);
        setCurrentPage(currentPage);
    }
    const handleChange = (pagination: any, filters: any, sorter: any) => {
        //
    }

    const handleStatusChange = async (record: any, value: number) => {
        try {
            await request({
                url: `${ApiUrl.PRODUCT}/change-defect-product-status`,
                method: 'post',
                body: {
                    serialNumber: record.serialNumber,
                    sku: sku,
                    status: value,
                }
            });
            return NotificationAlert('success', 'Товар обновлён');
        } catch {
            return NotificationAlert('error', 'При изменении статуса произошла ошибка.');
        }
    }

    const columns: any = [
        {
            title: 'Серийный номер дефекта',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
            render: (serialNumber: string) => {
                return <>{serialNumber}</>;
            },
            sorter: false,
        },
        {
            title: 'Количество',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (quantity: string) => {
                return <>{quantity}</>;
            },
            sorter: false,
        },
        {
            title: 'Цена продажи с учетом дефекта',
            dataIndex: 'priceRetail',
            key: 'priceRetail',
            render: (priceRetail: string) => {
                return <>{priceRetail}</>;
            },
            sorter: false,
        },
        {
            title: 'Код склада',
            dataIndex: 'stockCode',
            key: 'stockCode',
            render: (stockCode: string) => {
                return <>{stockCode}</>;
            },
            sorter: false,
        },
        {
            title: 'Дефект',
            dataIndex: 'defect',
            key: 'defect',
            render: (defect: string) => {
                return <>{defect}</>;
            },
            sorter: false,
        },
    ]

    const editStatusRoles = user && Array.isArray(user.roles) ? user.roles.find(item => {
        return item.value === ROLES.admin || item.value === ROLES.content || item.value === ROLES.operator
    }) : false;

    if (editStatusRoles) {
        columns.push( {
            title: 'Показывать на странице товара',
            dataIndex: 'status',
            key: 'status',
            render: (status: number, record: any) => {
                return  <Select
                    defaultValue={status ? 1 : 0}
                    onChange={(value) => handleStatusChange(record, value)}
                    style={{ width: '100%' }}
                >
                    <option value={1}>Да</option>
                    <option value={0}>Нет</option>
                </Select>
            },
            sorter: false,
        },
        )
    }
    return (
        <div>
            <Pagination
                style={{marginBottom: 20}}
                total={productsDefectsCount}
                current={currentPage}
                showQuickJumper={true}
                onChange={handlePagination}
                onShowSizeChange={onShowSizeChange}
                pageSizeOptions={[10, 25, 50, 100]}
                defaultPageSize={10}
            />
            <Table
                bordered
                columns={columns}
                dataSource={productDefects}
                pagination={false}
                onChange={handleChange}
                rowKey="serialNumber"
            />
        </div>
    )
}