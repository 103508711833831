import React from 'react';

export const InfoProvider: React.FC = () => {
  return (
    <div>
      Информация о поставщике
      <select>
        <option>Берем с сервера</option>
      </select>
      <input placeholder="Срок доставки" />
      <input placeholder="Название товара у поставщика" />
      <input placeholder="Артикул поставщика" />
      <input placeholder="Штрихкод" />
      <input placeholder="Артикул производителя" />
      <input placeholder="Закупочная цена" />
      <input placeholder="Розничная цена" />
    </div>
  );
};
