import React, { useEffect, useState } from 'react';
import { Pagination, Table } from 'antd';

import { useTypedSelector } from '../../../../../../hooks/useTypedSelector';
import styles from './HistoreSave.module.scss';
import { useActions } from '../../../../../../hooks/useActions';

interface HystoryProps {
  productId: string;
}

export const HistorySave: React.FC<HystoryProps> = ({ productId = '' }) => {
  const { productLogs, limit, currentPage, totalProductLogs } =
    useTypedSelector((state) => state.productLog);

  const [sort, setSort] = useState('DESC');

  const { fetchProductLogs } = useActions();

  useEffect(() => {
    fetchProductLogs({
      limit: limit,
      page: currentPage,
      sortId: sort,
      productId
    });
  }, []);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchProductLogs({
      limit: limit,
      page: currentPage,
      sortId: sort,
      productId,
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchProductLogs({
      limit: limit,
      page: currentPage,
      sortId: sort,
      productId,
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchProductLogs({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchProductLogs({ limit: limit, page: currentPage, sortId: 'DESC' });
      }
    }
  };

  const columns = [
    {
      title: 'ID лога',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return <>{id}</>;
      },
      sorter: true,
    },
    {
      title: 'Пользователь',
      key: 'user',
      render: (data: any) => {
        if (data.user) {
          return (
            `${
              data.user.lastName !== null && data.user.lastName !== 'null'
                ? data.user.lastName
                : ''
            }` +
            ' ' +
            `${
              data.user.firstName !== null && data.user.firstName !== 'null'
                ? data.user.firstName
                : ''
            }` +
            ' ' +
            `${
              data.user.middleName !== null && data.user.middleName !== 'null'
                ? data.user.middleName
                : ''
            }`
          );
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Дата',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        const createDate = new Date(createdAt).toLocaleString('ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        if (createdAt) {
          return <>{createDate}</>;
        } else {
          return 'Не указано';
        }
      },
    },
  ];

  return (
    <div className={styles.main}>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalProductLogs}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={productLogs}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
